export const Authorization = 'Authorization';
export const Token = 'token';
export const UserInfo = 'userInfo';

export const TeamInfo = 'teamInfo';

export enum LoginParams {
  Email = 'email',
  Password = 'password',
}

export enum LoginChannel {
  password = 'password',
}

export enum Permission {
  All = '',
  Me = 'me',
  Team = 'team',
  Department = 'department',
}

export enum UserSystemType {
  sys_super_admin = 'sys_super_admin', // 超级管理员
  sys_tenant_admin = 'sys_tenant_admin', // 企业管理员
  sys_user_normal = 'sys_user_normal', // 普通用户
}

export const UserSystemTypeText = {
  [UserSystemType.sys_super_admin]: '超级管理员',
  [UserSystemType.sys_tenant_admin]: '企业管理员',
  [UserSystemType.sys_user_normal]: '普通用户',
};

export enum UserTeamType {
  team_normal = 'team_normal', // 普通成员
  team_edit = 'team_edit', // 编辑成员
  team_admin = 'team_admin', // 团队管理员
}

export const UserTeamTypeText = {
  [UserTeamType.team_admin]: '团队管理员',
  [UserTeamType.team_edit]: '编辑成员',
  [UserTeamType.team_normal]: '普通成员',
};
